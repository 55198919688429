import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './login.scss';
import * as actions from '../../redux/actions';
import { Row, Col, Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import covifacturalogo from '../../assets/icons/covifacturalogo.svg';
import selectarrow from '../../assets/icons/selectarrow.svg';
import loginicon from '../../assets/icons/loginicon.svg';
import newlogo from '../../assets/logos/newlogo.svg';
import whitehelmet from '../../assets/icons/whitehelmet.svg';
import Loading from '../loading';
import FloatingFooter from '../floating-footer';
import Chat from '../chat';
import LoginBackground from '../login-background';
import config from '../../config';

class Login extends Component {
  constructor() {
    super();
    // 52763941
    this.state = {
      documentType: '',
      documentNumber: '',
      showError: false,
      videOpen: false,
      loginToken: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    const loginToken = params.get('token_seguridad');
    if (loginToken) {
      this.setState({
        loginToken,
      });
    }
    this.props.actions.getAppGlobals(true);
  }

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  handleSubmit = () => {
    const { documentType, documentNumber, loginToken } = this.state;
    if (loginToken) {
      this.props.actions.tokenLogin({
        documentType,
        documentNumber,
        loginToken,
      });
    } else {
      this.props.actions.getUserContact({
        documentType,
        documentNumber,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userContacts) {
      const { documentType, documentNumber } = this.state;
      if (documentNumber) {
        window.gtag('event', 'login', {
          event_category: `${documentType}:${documentNumber}`,
          event_label: 'seleccionar-contacto',
        });
      }
      this.props.history.push(config.publicRoute + `/seleccionar-contacto`);
    }
    if (nextProps.userLogin) {
      const { documentType, documentNumber } = this.state;
      console.log('nextProps.userLogin', nextProps.userLogin);
      window.gtag('event', 'login', {
        event_category: `${documentType}:${documentNumber}`,
        event_label: 'login',
      });
      this.props.actions.setUser({
        token: nextProps.userLogin.token,
        userInfo: nextProps.userLogin,
      });
      this.props.history.push(config.publicRoute + `/`);
    }
    if (nextProps.userContactsError) {
      this.props.actions.cleanUserContact();
      const { documentType, documentNumber } = this.state;
      window.gtag('event', 'login', {
        event_category: `${documentType}:${documentNumber}`,
        event_label: 'datos-erroneos',
      });
      this.showError();
      // this.props.history.push(config.publicRoute + `/datos-erroneos/${documentType}/${documentNumber}`);
    }
  }

  getErroText = () => {
    const { documentType, documentNumber } = this.state;
    if (documentType === 'N') {
      return `El ${this.getDocumentTypeName(
        documentType
      ).toLowerCase()} ${documentNumber} no se encuentra registrado en nuestro sistema.`;
    } else {
      return `La ${this.getDocumentTypeName(
        documentType
      ).toLowerCase()} ${documentNumber} no se encuentra registrada en nuestro sistema.`;
    }
  };

  getDocumentTypeName = (documentType) => {
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let documentTypeTemp = documentType === '' ? 'N' : documentType;
    if (documents.length > 0) {
      return documents.find((item) => item.ID === documentTypeTemp).LABEL;
    }
    return '';
  };

  showError = () => {
    this.setState(
      {
        showError: true,
      },
      () => {
        setTimeout(() => {
          this.setState({ showError: false });
        }, 3000);
      }
    );
  };

  changePage() {
    // window.open('https://clientes.covifactura.com', '_blank');
    window.location.href = config.covifactura;
  }

  redirectOnlinePayment() {
    window.location.href = config.onlinePayment;
  }

  render() {
    const { documentType, documentNumber, showError } = this.state;
    const { globals, loading, loadingUserContacts } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    return (
      <div className='login'>
        <Col>
          <Row className='full-center login-container'>
            <LoginBackground />
            
            <div className='login-logo-tablet hide-only-mobile hide-desktop'>
              <img className='' src={newlogo} alt='covifactura logo' />
              
            </div>
            {/* <button
              className='button outline-button-caja-ya'
              onClick={this.redirectOnlinePayment}
            >
              Pague
              <div className='logo-caja-ya' />
            </button>  */}
            
            <div className='mobile-side login-card'>
            
              <Col>
              
                <Row>
                  {(loading || loadingUserContacts) && <Loading />}
                 
                  <Col xs={12} className='login-tabs'>
                  
                    <Row>
                      
                      <Col
                        xs={12}
                        className='mobile-logo-container hide-ipad hide-desktop'
                      >
                         
                        <img
                          className='mobile-logo'
                          src={covifacturalogo}
                          alt='covifactura icon'
                        />
                      </Col>
                      
                      <Col xs={6}>
                        <button
                          className='button outline-button'
                          onClick={this.changePage}
                        >
                          Afiliados
                          <div className='button-house' />
                        </button>
                      </Col>
                      <Col xs={6}>
                        <button className='button button-primary selected-button'>
                          Compradores
                          <img
                            className='button-helmet'
                            src={whitehelmet}
                            alt='helmet icon'
                          />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className='login-card_title'>
                    Iniciar sesión
                  </Col>
                  <Col xs={12} className='login-card_subtitle'>
                    Ingrese su tipo y número de documento.
                  </Col>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    className='login-input-container'
                  >
                    <label for='documentType'>Tipo de documento</label>
                    <select
                      id='documentType'
                      name='documentType'
                      value={documentType}
                      className='login-input'
                      onChange={(event) => {
                        this.onInputChange(event, 'documentType');
                      }}
                    >
                      <option value=''></option>
                      {documents.map((item, index) => {
                        return (
                          <option key={index} value={item.ID}>
                            {item.LABEL}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      className='select-arrow'
                      src={selectarrow}
                      alt='select arrow'
                    />
                  </Col>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    className='login-input-container'
                  >
                    <label for='documentNumber'>Número de documento</label>
                    <input
                      id='documentNumber'
                      name='documentNumber'
                      value={documentNumber}
                      className='login-input'
                      onChange={(event) => {
                        this.onInputChange(event, 'documentNumber');
                      }}
                    />
                  </Col>
                  {showError && (
                    <Col xs={{ span: 6, offset: 3 }} className='error-message'>
                      {this.getErroText()}
                    </Col>
                  )}
                  <Col xs={{ span: 6, offset: 3 }} className='button-container'>
                    <button
                      disabled={documentNumber === '' || documentType === ''}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleSubmit();
                      }}
                      className='button button-primary secundary-color'
                    >
                      Continuar
                      <img
                        className='button-login'
                        src={loginicon}
                        alt='login icon'
                      />
                    </button>
                  </Col>
                  <Col xs={12} className='login-terms'>
                    Al continuar usted acepta los{' '}
                    <a
                      href='https://covinoc.com/politicas/terminos-y-condiciones'
                      target='_blank'
                      className='underline'
                    >
                      términos, condiciones
                      <br />
                    </a>
                    <span> y </span>
                    <a
                      href='https://covinoc.com/politicas/aviso-privacidad'
                      target='_blank'
                      className='underline'
                    >
                      políticas de privacidad.
                    </a>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </Col>
        <Chat home />
        <FloatingFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    globals,
    loading,
    loadingUserContacts,
    userContacts,
    userContactsError,
    userLogin,
  } = state.user;
  return {
    globals,
    loading,
    loadingUserContacts,
    userContacts,
    userContactsError,
    userLogin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
