import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './assets/latofonts.css';
import './assets/bootstrap.min.css';
import './App.scss';

class App extends Component {
  constructor () {
    let today = new Date(2024, 6, 22); // Ajustar la fecha
    today.setHours(8, 10, 0); // Ajustar la hora
    const commitMessage = "Actualización del enlace de redirección contenido en el botón de '¿Necesita Ayuda?'.";
    const shaCommit = "0ff8f7c9"; // Ajustar el sha del commit
    const author = "Tatiana Ramos Villanueva"; // Ajustar el autor del commit
    const featureMessage = `${new Date(today)} - ${commitMessage} - ${author} - ${shaCommit}`;
    console.info(featureMessage);
    super();
  }

  static propTypes={
    children: PropTypes.object.isRequired
  };

  render() {
    const { children }= this.props;

    return (
      <div className="App">
        { children }
      </div>
    );
  }
}

export default App;
