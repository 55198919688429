import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./other-data.scss";
import * as actions from "../../redux/actions";
import { Row, Col } from "react-bootstrap";

import leftArrow from "../../assets/icons/left-arrow.svg";
import phone from "../../assets/icons/icon-phone.svg";
import callcenterIcon from "../../assets/icons/icon-callcenter.svg";
import chaticon from "../../assets/icons/icon-whatsapp.svg";
import Loading from "../loading";
import LoginBackground from "../login-background";
import newlogo from "../../assets/logos/newlogo.svg";
import FloatingFooter from "../floating-footer";
import Chat from "../chat";
import config from "../../config";

class OtherData extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.actions.getAppGlobals();
  }

  goBack = () => {
    this.props.actions.cleanUserContact();
    this.props.history.push(config.publicRoute + `/login`);
  };

  openChat() {
    window.gtag("event", "chat_whapp", {
      event_category: "sin-login",
      event_label: "sin-login",
    });
    window.open(
      "https://api.whatsapp.com/send?phone=573102222377",
      "_newtab"
    );
  }
  openNumber(number) {
    window.open("tel:"+number, "_newtab");
  }

  render() {
    const { globals, loading } = this.props;
    return (
      <div className="other-data login">
        <Col>
          <Row className="full-center login-container">
            <LoginBackground />
            <div className="login-logo-tablet hide-only-mobile hide-desktop">
              <img className="" src={newlogo} alt="covifactura logo" />
            </div>
            <div className="mobile-side login-card">
              <Col>
                <Row>
                  {/* <Col xs={12} className='full-center'>
                    <img
                      className='other-data-logo'
                      src={loginlogo}
                      alt='covifactura logo'
                    />
                  </Col> */}
                  {loading && <Loading />}
                  <Col
                    xs={12}
                    className="other-data-card_icon-back-container"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goBack();
                    }}
                  >
                    <img
                      className="other-data_icon-back"
                      src={leftArrow}
                      alt="error icono"
                    />
                    Volver al inicio de sesión
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className="other-data-card_title"
                  >
                    ¿No son sus datos de
                    <br /> contacto?
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className="other-data-card_subtitle"
                  >
                    Comuníquese con nosotros para
                    <br />
                    hacer una actualización de datos
                  </Col>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    className="other-data-card_option"
                  >
                    <Col
                      className="other-data-card_button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.openChat();
                      }}
                    >
                      <Row className="full-center">
                        <Col
                          md="auto"
                          className="d-flex justify-content-center"
                        >
                          <img
                            className="other-data_icon-chaticon"
                            src={chaticon}
                            alt="chat icono"
                          />
                          <div className="other-data_option ml-1">Whatsapp</div>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className="other-data-card_subtitle"
                  >
                    o llámenos
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className="d-flex flex-column justify-content-center"
                  >
                    <Row>
                      <Col className="other-data-card_subtitle d-flex justify-content-start">
                        En Bogotá
                      </Col>
                      <Col
                        className="d-flex justify-content-end"
                        onClick={(e) => {
                          e.preventDefault();
                          this.openNumber("6013534324");
                        }}
                      >
                        <img height={"15px"} src={phone} alt="chat icono" />
                        <div className="number-align ml-1">6013534324</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="other-data-card_subtitle d-flex justify-content-start">
                        A nuestra línea nacional
                      </Col>
                      <Col
                        className="d-flex justify-content-end"
                        onClick={(e) => {
                          e.preventDefault();
                          this.openNumber("018000946969");
                        }}
                      >
                        <img
                          height={"15px"}
                          src={callcenterIcon}
                          alt="chat icono"
                        />
                        <div className="number-align ml-1">018000946969</div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className="other-data-card_subtitle"
                  >
                    Horario de atención
                    <br />
                    Lunes a Viernes 7:30 a.m - 5:30 p.m
                  </Col>
                  <Col xs={12} className="login-terms">
                    Al continuar usted acepta los{" "}
                    <a
                      href="https://covinoc.com/politicas/terminos-y-condiciones"
                      target="_blank"
                      className="underline"
                    >
                      términos, condiciones
                      <br />
                    </a>
                    <span> y </span>
                    <a
                      href="https://covinoc.com/politicas/aviso-privacidad"
                      target="_blank"
                      className="underline"
                    >
                      políticas de privacidad.
                    </a>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </Col>
        <Chat home />
        <FloatingFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { globals, loading } = state.user;
  return {
    globals,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherData);
